<app-layout>
  <div class="container">
    <table>
      <tr *ngFor="let action of userActions">
          <td>{{ action.user_id }}</td>
          <td>{{ action.path }}</td>
          <td>{{ action.method }}</td>
          <td>{{ action.data }}</td>
          <td>{{ action.created_at }}</td>
      </tr>
    </table>
  </div>
</app-layout>
