import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAction } from '../models/useraction';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UserActionService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  private getUrl(): string {
    return this.getBaseUrl() + 'user-actions';
  }

  public getUserActions(): Promise<UserAction[]> {
    return this.http.get<UserAction[]>(this.getUrl()).toPromise();
  }
}
