import { Component, OnInit } from '@angular/core';
import { UserAction } from 'src/app/models/useraction';
import { UserActionService } from 'src/app/services/user-action.service';

@Component({
  selector: 'app-user-action-list',
  templateUrl: './user-action-list.component.html',
  styleUrls: ['./user-action-list.component.scss']
})
export class UserActionListComponent implements OnInit {

  userActions: UserAction[] = [];

  constructor(private userActionService: UserActionService) { }

  ngOnInit(): void {
    this.getUserActions();
  }

  getUserActions(){
    this.userActionService.getUserActions().then(actions => {
      this.userActions = actions;
    });
  }


}
