<app-layout>
    <div class="container">
        <div style="display:flex;justify-content: space-between;">
            <h1 *ngIf="!!user">{{user.firstname + ', ' + user.lastname + ' (' + user.username + ')'}}</h1>

            <mat-icon class="c-pointer" color="primary" routerLink="./"
                [queryParams]="{openDialog: true, userId: userid}" *hasPermission="[WRITE_PEOPLE]">create
            </mat-icon>
        </div>

        <div *hasPermission="[READ_TIMES]; user user">
        
            <h2>{{'common.timeentries' | translate}}</h2>
            <table mat-table [dataSource]="entryDatasource" class="mytable dark-header" style="width:100%;margin-top:10px">

                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> {{'common.id' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- date Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> {{'common.date' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.start_time | date:'d.M.Y':'+0'}} </td>
                </ng-container>


                <!-- start_time Column -->
                <ng-container matColumnDef="start_time">
                    <th mat-header-cell *matHeaderCellDef> {{'timeentry.start_time' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.start_time | date:'HH:mm:ss':'+0'}} </td>
                </ng-container>

                <!-- end_time Column -->
                <ng-container matColumnDef="end_time">
                    <th mat-header-cell *matHeaderCellDef> {{'timeentry.end_time' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.end_time | date:'HH:mm:ss':'+0'}} </td>
                </ng-container>

                <!-- time Column -->
                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef> {{'common.time' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.time}} min</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsEntries"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsEntries;"></tr>
            </table>
            <mat-paginator showFirstLastButtons [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 200]" #entryPaginator>
            </mat-paginator>

        </div>
        
        <div *hasPermission="[READ_BADGES]; user user">
        
            <h2>{{'common.badges' | translate}}</h2>
            <table mat-table [dataSource]="badgeDatasource" class="mytable" style="width:100%;margin-top:10px">

                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> {{'common.id' |translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{'badge.name' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>


                <!-- rfid Column -->
                <ng-container matColumnDef="rfid">
                    <th mat-header-cell *matHeaderCellDef> {{'badge.rfid' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.rfid}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsBadges"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsBadges;"></tr>
            </table>
            <mat-paginator showFirstLastButtons [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 200]" #badgePaginator>
            </mat-paginator>

        </div>
        
    </div>
</app-layout>